<template>
  <div class="wrapper">
    <el-container>
      
      <el-header style="height: 60px;">
      <div class="tab_title" style="height: 60px;">
        
          
          <img style="width: 150px;margin: 5px 0px 0px 3px;float: left;" src="imgs/logomogo.jpg">
          <el-menu style="height: 50px;margin: 0 0 0 5px;float: left;" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item style="height: 50px;"  index="2" >crm项目信息</el-menu-item>
          </el-menu>
      
      </div>
      </el-header>
  <div id="app">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <Form v-if="tab=='2'"/>
  </div>
  <el-footer id="footer">
    <div class="copyright">
        <a class="text" @click="jump"><span>浙ICP备16017533号-4</span></a>
        <span class="text">杭州莫戈金属有限公司</span>
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=33010902003923" rel="noreferrer" target="_blank">
        <img style="height: 25px; margin-right: -5px;" src="imgs/beian.png">
        <span class="text">浙公网安备33010902003923</span>
      </a>
    </div>
  </el-footer>
    </el-container>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import axios from 'axios'
import Form from './components/form'
export default {
  name: 'App',
  data:function(){
    return{
      tab:'2',
      activeIndex:'2'
    }
  },
  methods:{
    handleSelect(key){
        this.tab = key
        this.activeIndex = key
      
    },
    jump() {

   window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank')
    }
    
    },
    
     

  
  components: {
    // HelloWorld, 
    
    Form
    
  }
}
</script>

<style>
#app {
   font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px; 
}
#footer{
  left: 0px;
  line-height: 60px;
  background-color: burlywood;
}
.copyright{
  height:30px;
  line-height: 30px;
  text-align: center;
}
img{
  vertical-align: middle;
  margin-left: 10px;
}
.text{
  vertical-align: middle;
  margin-left: 10px;
}
span{
  color: rgb(65, 63, 62);
}
</style>
