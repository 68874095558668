import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import 'font-awesome/css/font-awesome.min.css'
import axios from 'axios'
import '@/assets/css/login.css'
import '@/assets/css/orderset(1).css'
import '@/assets/css/style.css'
import xbb from 'xbb-sdk'
// axios.defaults.baseURL = "http://192.168.2.145:28019/home"
Vue.prototype.$axios = axios
Vue.use(ElementUI)
Vue.use(axios)
Vue.config.productionTip = false
Vue.use(xbb)


   
new Vue({
  render: h => h(App),
}).$mount('#app')
