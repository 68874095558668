<template>
<div id = "app">
  <el-form  ref="ruleForm" label-width="100px" class="demo-ruleForm">
  <el-form-item label="项目名称" >
    <el-select   @change="searchMore" v-model="projectDetail.projectName" filterable placeholder="请选择项目名称" >
    <el-option
      v-for="(item,i) in projectNames"
      :key="i"
      :label="item"
      :value="item"
      >
    </el-option>
  </el-select>
  <el-button @click="handleClick" >获取名称列表</el-button>
    <!-- <el-select v-model="projectDetail.projectName" placeholder="请选择项目名称">
        <el-option v-for="item in projectNames" :label="item.value" :value="item.value" :key="item.value"></el-option>
      <el-option label="区域一" value="shanghai"></el-option>
      <el-option label="区域二" value="beijing"></el-option>
    </el-select> -->
  </el-form-item>
  <el-form-item label="项目编号" >
    <el-input v-model="projectDetail.projectId"></el-input>
  </el-form-item>
  <el-form-item label="项目类型" >
    <el-input v-model="projectDetail.projectType"></el-input>
  </el-form-item>
  <el-form-item label="测试" >
    <el-input v-model="testvalue"></el-input>
  </el-form-item>
</el-form>
</div>

</template>

<script>
import axios from 'axios'
import xbb from 'xbb-sdk'

  export default {
    methods: {
      searchMore(projectName){
        var param={
          searchName:projectName
        }
        this.$axios.post("api/MGJS/vx.asmx/searchJmType",param).then((res)=>{
        this.projectDetail.projectType = res.data.msg;
        this.projectDetail.projectId = res.data.result;
        console.log(this.projectDetail);
      })
      },
      handleClick(){
        this.getNames();
      },
      getNames(){
        this.$axios.post("api/MGJS/vx.asmx/searchProject").then((res)=>{
        this.projectNames = res.data.data;
      })
      }
    }, 
    created:function(){
      this.getNames()
      xbb.ready((props)=>{
          xbb.onMessage('closeDialog',(e)=>{
           xbb.closeContainer({data:[this.projectDetail.projectName,this.projectDetail.projectId,this.projectDetail.projectType]}
           );
           
          });
      })
    },
    data() {
      return {
        testvalue:"",
        projectNames:["11","22","33"],
         projectDetail:{
          projectName:"",
          projectType:"",
          projectId:""
         },
      }
    }
  }
 
 
   
</script>
<style >
  .el-form .el-form-item{
      width:300px
    }
   
  body > div.el-select-dropdown.el-popper > div.el-scrollbar > div.el-select-dropdown__wrap.el-scrollbar__wrap > ul > li {
    
    width: 350px;
    white-space: normal;
    /* display: inline; */
    line-height: 24px;
    height: auto;
    /* word-break: break-all;
    word-wrap: break-word; */
  }
  body > div.el-select-dropdown.el-popper > div.el-scrollbar > div.el-select-dropdown__wrap.el-scrollbar__wrap > ul > li:hover {
    background-color:lightgray;
  }


</style>